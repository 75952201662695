import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "description",
        },
        {
          key: "code",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
          label: "Edit",
        },
      ],
      form: {
        id: "",
        name: "",
        description: "",
        code: "",
        is_active: 0,
      },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    changestatus() {
      if (this.status) {
        this.status = false;
      } else {
        this.status = true;
      }
    },

    async fetchProducts() {
      const url = indonesia_payment?.getGiftCompany;
      const data = await this.getRequest(`${apiEndpoint}${url}`);
      if (data.status) {
        this.products = data.response;
      }
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "trash") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${indonesia_payment?.getGiftCompany}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${txt ?? ""}`;
      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "Somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateGiftCompany}/${id}`;
        const data = await axios.post(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }

        let dataAppend = {};
        for (var key in this.form) {
          if (key == "id") continue;
          dataAppend[key] = this.form[key];
        }
        this.$store.commit("loader/updateStatus", true);
        if (this.$route.name == "edit-gift-company") {
          let url = `${apiEndpoint}${indonesia_payment?.getGiftCompany}/${this.form.id}`;
          const request = await axios.put(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/gift-company");
          }
        } else {
          let url = `${apiEndpoint}${indonesia_payment?.getGiftCompany}`;
          const request = await axios.post(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/gift-company");
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    // async submitData() {
    //   this.$store.commit("loader/updateStatus", true);
    //   try {
    //     let formData = new FormData();
    //     if (this.form.is_active) {
    //       formData.append("is_active", 1);
    //     } else {
    //       formData.append("is_active", 0);
    //     }
    //     formData.append("name", this.form.name);
    //     formData.append("description", this.form.description);
    //     formData.append("code", this.form.code);
    //     formData.append("_method", "PUT");
    //     let url = "";
    //     let request = "";
    //     if (this.$route.name == "edit-gift-company") {
    //       url = `${apiEndpoint}${indonesia_payment?.getGiftCompany}/${this.$route.params.id}`;
    //       request = await axios.put(url, formData);
    //     } else {
    //       url = `${apiEndpoint}${indonesia_payment?.getGiftCompany}`;
    //       request = await axios.ppost(url, formData);
    //     }
    //     const response = request.data;
    //     if (response.status) {
    //       this.$store.commit("toast/updateStatus", {
    //         status: true,
    //         icon: "success",
    //         title: response.message,
    //       });
    //       this.$router.push("/gift-company");
    //     }
    //   } catch (err) {
    //     this.$store.commit("toast/updateStatus", {
    //       status: true,
    //       icon: "error",
    //       title: err.message ? err.message : "Please try again........!",
    //     });
    //     console.log(err, "err in catch");
    //   }
    //   this.$store.commit("loader/updateStatus", false);
    // },

    async fetchGiftCompany(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const request = `${indonesia_payment?.getGiftCompany}/${id}`;
        const response = await axios.get(`${apiEndpoint}${request}`);
        const res = response?.data;
        if (res) {
          const responseData = res.response;
          if (responseData.id !== "" && responseData.id !== null) {
            this.form.id = responseData.id;
          }
          if (responseData.name !== "" && responseData.name !== null) {
            this.form.name = responseData.name;
          }
          if (responseData.code !== "" && responseData.code !== null) {
            this.form.code = responseData.code;
          }
          if (
            responseData.description !== "" &&
            responseData.description !== null
          ) {
            this.form.description = responseData.description;
          }
          if (responseData.is_active === 1) {
            this.form.is_active = true;
          } else {
            this.form.is_active = false;
          }
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        console.error("Error fetching payment gateway:", err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-gift-company" ||
      this.$route.name == "edit-gift-company"
    ) {
      this.fetchProducts();
      if (this.$route.name == "edit-gift-company") {
        this.fetchGiftCompany(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
